import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { DashboardState } from "../@types/configurator";
import { ProductData } from "../@types/product";

const initialState: DashboardState = {
  products: [],
};

export const resetDashboard = createAction("REVERT_ALL");

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<ProductData[]>) => {
      state.products = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(resetDashboard, () => initialState),
});

export const { setProducts } = dashboardSlice.actions;

export default dashboardSlice.reducer;
