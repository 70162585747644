import {
  AquariumType,
  DeliveryOption,
  DoorType,
  ElectraRuimte,
  ExtraLEDFreshWaterOption,
  ExtraLEDSaltWaterOption,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureType,
  PvcPipeworkPackage,
  TurtleIsland,
} from "./aquarium";
import { ProductData } from "./product";
import { DecorTexture } from "./textures";
import { UserRole } from "./user";

export enum Steps {
  aquariumType = "aquariumtype",
  dimensions = "dimensions",
  windowPaint = "windowpaint",
  filter = "filter",
  furniture = "furniture",
  technics = "technics",
}

export enum StepStatus {
  toDo = "todo",
  isCompleted = "iscompleted",
  hasIssue = "hasissue",
}

export type StepObj = {
  name: Steps;
  step: number;
  title: string;
};

export type ImageOption = {
  id:
    | AquariumType
    | DecorTexture
    | FullGlassCoverPanels
    | TurtleIsland
    | FilterOptions
    | FilterLocation
    | FurnitureType
    | ElectraRuimte
    | DoorType
    | ExtraLEDFreshWaterOption
    | ExtraLEDSaltWaterOption
    | PvcPipeworkPackage;
  img: string;
  title: string;
};

export type TextOption = {
  id: DeliveryOption;
  title: string;
};

export type OptionList = {
  options: Array<ImageOption>;
  name: string;
};

export type ConfiguratorState = {
  openTexturesModal: boolean;
  openFilterLocationModal: boolean;
  showOverviewScreen: boolean;
  stepInfoList: StepInfo[];
  stepItemInfoOverview: StepItemInfoList;
  openStep: Steps | null;
  openProductInfoModal: ProductData | null;
  URLAquariumLoaded: boolean;
  aquariumSnapshot: string | null;
  showHelpModal: boolean;
  checkRole: UserRole | null | undefined;
  filterWidthInfo: FilterWidthInfo;
  glassThiknesses: GlassThiknesses;
  prices: Prices;
  widthStripValues: WidthStripValues;
};

export type DashboardState = {
  products: ProductData[];
};

export enum DashboardPages {
  offertes = "offertes",
  translations = "translations",
  products = "products",
  users = "users",
}

export type SelectOption = {
  value: any;
  title: string;
};

export enum CountryOptions {
  belgium = "BE",
  netherlands = "NL",
  germany = "DE",
  france = "FR",
  luxembourg = "LU",
  bulgaria = "BG",
  czechia = "CZ",
  denmark = "DK",
  estonia = "EE",
  ireland = "IE",
  greece = "EL",
  spain = "ES",
  croatia = "HR",
  italy = "IT",
  cyprus = "CY",
  latvia = "LV",
  lithuania = "LT",
  hungary = "HU",
  malta = "MT",
  austria = "AT",
  poland = "PL",
  portugal = "PT",
  romania = "RO",
  slovenia = "SI",
  slovakia = "SK",
  finland = "FE",
  sweden = "SE",
  switzerland = "CH",
  norway = "NO",
}

export type StepInfo = {
  title: Steps;
  status: StepStatus;
  disabled: boolean;
  opened: boolean;
};

export type StepOpenedInfo = {
  title: Steps;
  isOpened: boolean;
};

export type ClosetDimensions = {
  width: number;
  depth: number;
  height: number;
};

export type StepInfoShort = Pick<StepInfo, "status" | "disabled">;

export type StepItemInfoList = {
  dimensions: {
    aquariumDimensions: {
      hasIssue: boolean;
    };
    sharpenGlass: {
      hasIssue: boolean;
    };
  };

  aquariumType: {
    waterType: {
      hasIssue: boolean;
    };
    aquariumType: {
      hasIssue: boolean;
    };
    aquariumTexture: {
      hasIssue: boolean;
    };
    coverPanels: {
      hasIssue: boolean;
    };
    fullGlassCoverPanels: {
      hasIssue: boolean;
    };
    turtleIsland: {
      hasIssue: boolean;
    };
    lightCover: {
      hasIssue: boolean;
    };
  };

  windows: {
    windowPaintColor: {
      hasIssue: boolean;
    };
    paintWindows: {
      hasIssue: boolean;
    };
    optiWhiteWindows: {
      hasIssue: boolean;
    };
  };

  filter: {
    filterType: {
      hasIssue: boolean;
    };
    filterLocation: {
      hasIssue: boolean;
    };
    externalDryroom: {
      hasIssue: boolean;
    };
    bioCoverPanels: {
      hasIssue: boolean;
    };
    osmoseCompartiment: {
      hasIssue: boolean;
    };
    boreholes: {
      hasIssue: boolean;
    };
  };

  furniture: {
    furnitureType: {
      hasIssue: boolean;
    };
    dimensions: {
      hasIssue: boolean;
    };
    aquariumTexture: {
      hasIssue: boolean;
    };
    extraOptions: {
      hasIssue: boolean;
    };
    doorType: {
      hasIssue: boolean;
    };
    electraRuimte: {
      hasIssue: boolean;
    };
    removableSides: {
      hasIssue: boolean;
    };
  };
};

export type FilterWidthInfo = {
  aquariumWidth: string[];
  filterWidthDiff: string[];
  sideCompWidth: string[];
  electrabreedte: string[];
};

export type GlassThiknesses = {
  horizontalValues: string[];
  verticalValues: string[];
  thicknessValues: string[][];
};

export type Prices = {
  thing: string[];
  price: string[];
  verdeler1_price: string[];
  verdeler2_price: string[];
};

export type WidthStripValues = {
  horizontalValues: string[];
  verticalValues: string[];
  amount_strips: string[][];
};
