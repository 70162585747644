import {
  Aquarium,
  AquariumCalculatorNeeds,
  AquariumDimensions,
  AquariumInterface,
  AquariumType,
  DoorType,
  ElectraRuimte,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureDimensions,
  FurnitureType,
  LightboardFinish,
  LightCover,
  Side,
  SideGlassColor,
  SideInfo,
  TurtleIsland,
  Furniture,
  SidesInfo,
  PvcPipeworkPackage,
} from "../../@types/aquarium";
import rimlessThickness from "./jsonFiles/rimless.json";
import lightcoverThickness from "./jsonFiles/lightcover.json";
import fullglassThickness from "./jsonFiles/fullglass.json";
import turtleThickness from "./jsonFiles/turtle.json";
import { Dict } from "styled-components/dist/types";
import { setAquariumDimensions } from "../../redux/aquariumSlice";
import {
  minLengthstripDepth,
  minLengthstripWidth,
  minSideWithstripDepth,
  widthstripsDepth,
} from "./fullGlass";
import { totalmem } from "os";
import { lidwidths } from "./lightCover";
import {
  getAmountCompartiments,
  outerPlankOverlap,
  upperBottomPlankStripDepth,
} from "./marineConform";
import {
  bottomHeightDiff,
  electraPlateWidth,
  getAmountSkeletonCompartiments,
  getPoleThickness,
} from "./skeleton";
import {
  biggerFilterHeight,
  closetHeightchanger,
  closetHeightdisappear,
  filterDepthDiff,
  getFilterWidthDiff,
  maxFilterDepth,
  smallerFilterHeight,
} from "./bioFilter";
import { bridgeWidthDepth, platformHeight, platformWidth } from "./turtle";
import {
  bottomPlanksHeightDiff,
  getSideCompWidth,
  getSumpFilterWidthDiff,
  maxSumpFilterDepth,
  sumpFilterDepthDiff,
  sumpFilterHeight,
} from "./sumpFilter";
import { betonplexlimit } from "./tank";
import { store } from "../../redux/store";

const getPrices = () => {
  return store.getState().configurator.prices;
};

const getWidthStripValues = () => {
  return store.getState().configurator.widthStripValues;
};

const coverGlassMinimumSqm = 0.5;
const scale = 25;

//glass price
//type price
//filter price
//furnitur price
export function getPrice(aquarium: Aquarium): number {
  // console.log(JSON.stringify(aquarium))
  let glassPrice = calculateAquariumGlassPrice(
    aquarium.aquariumDimensions,
    aquarium.aquariumSides,
    aquarium.aquariumType,
    aquarium.sharpenGlass,
    aquarium.extra.waterproofPlate
  );
  let typePrice = getTypePrice(aquarium.aquariumDimensions, aquarium);

  let furniturePrice = getFurniturePrice(
    aquarium.aquariumDimensions,
    aquarium.furniture,
    aquarium.furniture.furnitureType
  );
  let filterPrice = getFilterPrice(aquarium);
  let totalPrice = glassPrice + typePrice + furniturePrice + filterPrice;
  let marge = totalPrice * Number(getPrices()["price"][getPriceIndex("marge")]);
  //let btw = totalPrice * Number(getPrices()["price"][getPriceIndex("btw")]);
  return totalPrice + marge// + btw;
}

function getFurniturePrice(
  aquariumDimensions: AquariumDimensions,
  furniture: Furniture,
  furnitureType: FurnitureType | null
): number {
  switch (furnitureType) {
    case FurnitureType.aluminium: {
      let totalPrice = 0;
      //skelet
      totalPrice += getFurniturePrice(
        aquariumDimensions,
        furniture,
        FurnitureType.frameOnly
      );
      // console.log("framePrice: ", totalPrice);
      //aluminium platen
      if (furniture.dimensions.topHeight > 0) {
        //bovenstel
        totalPrice +=
          ((2 *
            (furniture.dimensions.topHeight + outerPlankOverlap) *
            aquariumDimensions.depth) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("aluminium_finish")]);
      }
      //onderstel
      totalPrice +=
        ((2 *
          (furniture.dimensions.baseHeight + outerPlankOverlap) *
          aquariumDimensions.depth) /
          10000) *
        Number(getPrices()["price"][getPriceIndex("aluminium_finish")]);
      //deuren
      if (furniture.doorType === DoorType.removablePanels) {
        // onderste deuren
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("removable_panels_aluminium")]);
        // bovenste deuren
        if (furniture.dimensions.topHeight > 0)
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(
              getPrices()["price"][getPriceIndex("removable_panels_aluminium")]
            );
      } else if (
        furniture.doorType === DoorType.pushDoors ||
        furniture.doorType === null
      ) {
        // onderste deuren
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("push_doors_aluminium")]);
        // console.log(
        //   "deurprijs:",
        //   (((furniture.dimensions.baseHeight + outerPlankOverlap) *
        //     aquariumDimensions.width) /
        //     10000) *
        //     Number(getPrices()["price"][getPriceIndex("push_doors_aluminium")])
        // );
        // bovenste deuren
        if (furniture.dimensions.topHeight > 0)
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("push_doors_aluminium")]);
      } else if (furniture.doorType === DoorType.slidingDoors) {
        // onderste deuren
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("sliding_doors_aluminium")]);
        // bovenste deuren
        if (furniture.dimensions.topHeight > 0)
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("sliding_doors_aluminium")]);
      }
      //bovenstel marineconform
      if (
        furniture.dimensions.topHeight < 75 &&
        furniture.dimensions.topHeight > 0
      )
        totalPrice +=
          ((2 * aquariumDimensions.depth * furniture.dimensions.topHeight +
            aquariumDimensions.depth * aquariumDimensions.width +
            aquariumDimensions.width * furniture.dimensions.topHeight +
            aquariumDimensions.width * upperBottomPlankStripDepth) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
      else if (furniture.dimensions.topHeight >= 75)
        totalPrice +=
          ((2 * aquariumDimensions.depth * furniture.dimensions.topHeight +
            ((getAmountCompartiments(aquariumDimensions.width) - 1) *
              aquariumDimensions.depth *
              furniture.dimensions.topHeight) /
              2 +
            2 * aquariumDimensions.depth * aquariumDimensions.width +
            aquariumDimensions.width * furniture.dimensions.topHeight +
            aquariumDimensions.width * upperBottomPlankStripDepth) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
      //seethrough
      if (furniture.seeThrough) {
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("aluminium_finish")]);
        if (furniture.dimensions.topHeight > 0) {
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("aluminium_finish")]);
        }
      }
      //electraruimte
      if (
        furniture.electraRuimte === ElectraRuimte.left ||
        furniture.electraRuimte === ElectraRuimte.right
      ) {
        totalPrice +=
          ((furniture.dimensions.baseHeight * aquariumDimensions.depth +
            electraPlateWidth * furniture.dimensions.baseHeight) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("electra_closet")]);
        if (furniture.aquaswitch)
          totalPrice += Number(getPrices()["price"][getPriceIndex("aquaswitch")]);
      }
      return totalPrice;
    }
    case FurnitureType.frameOnly: {
      let totalPrice = 0;
      let poleThickness = getPoleThickness(
        aquariumDimensions.width,
        aquariumDimensions.height,
        aquariumDimensions.depth
      );
      //skelet
      let skeletonPrice;
      if (poleThickness === 4) {
        skeletonPrice = Number(getPrices()["price"][getPriceIndex("rvs_40mm")]);
      } else {
        skeletonPrice = Number(getPrices()["price"][getPriceIndex("rvs_30mm")]);
      }
      //skeleton width
      totalPrice += ((4 * aquariumDimensions.width) / 100) * skeletonPrice;
      //skeleton height

      totalPrice +=
        ((2 *
          (getAmountSkeletonCompartiments(aquariumDimensions.width) + 1) *
          furniture.dimensions.baseHeight) /
          100) *
        skeletonPrice;
      //skeleton depth
      totalPrice +=
        ((2 *
          (getAmountSkeletonCompartiments(aquariumDimensions.width) + 1) *
          aquariumDimensions.depth) /
          100) *
        skeletonPrice;
      if (furniture.dimensions.topHeight > 0) {
        //skeleton width
        totalPrice += ((4 * aquariumDimensions.width) / 100) * skeletonPrice;
        //skeleton height

        totalPrice +=
          ((2 *
            (getAmountSkeletonCompartiments(aquariumDimensions.width) + 1) *
            furniture.dimensions.topHeight) /
            100) *
          skeletonPrice;
        //skeleton depth
        totalPrice +=
          ((2 *
            (getAmountSkeletonCompartiments(aquariumDimensions.width) + 1) *
            aquariumDimensions.depth) /
            100) *
          skeletonPrice;
      }
      //poten
      let legPrice;
      if (poleThickness === 4) {
        legPrice = Number(getPrices()["price"][getPriceIndex("leg_40mm")]);
      } else {
        legPrice = Number(getPrices()["price"][getPriceIndex("leg_30mm")]);
      }
      totalPrice +=
        2 *
        (getAmountSkeletonCompartiments(aquariumDimensions.width) + 1) *
        legPrice;
      //betonplex
      if (furniture.betonPlex)
        totalPrice +=
          ((aquariumDimensions.depth * aquariumDimensions.width) / 10000) *
          Number(getPrices()["price"][getPriceIndex("betonplex_18mm")]);

      //(currLowerClosetHeight/scale-bottomHeightDiff/scale-poleThickness/scale)*3/4
      if (furniture.backWall34)
        totalPrice +=
          ((aquariumDimensions.width *
            (furniture.dimensions.baseHeight -
              bottomHeightDiff -
              poleThickness) *
            3) /
            4 /
            10000) *
          Number(getPrices()["price"][getPriceIndex("betonplex_18mm")]);
      return totalPrice;
    }
    case FurnitureType.decor: {
      let totalPrice = 0;
      //skelet
      totalPrice += getFurniturePrice(
        aquariumDimensions,
        furniture,
        FurnitureType.frameOnly
      );
      //decor platen
      if (furniture.dimensions.topHeight > 0) {
        //bovenstel
        totalPrice +=
          ((2 *
            (furniture.dimensions.topHeight + outerPlankOverlap) *
            aquariumDimensions.depth) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
      }
      //onderstel
      totalPrice +=
        ((2 *
          (furniture.dimensions.baseHeight + outerPlankOverlap) *
          aquariumDimensions.depth) /
          10000) *
        Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
      //deuren
      if (furniture.doorType === DoorType.removablePanels) {
        // onderste deuren
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("removable_panel_decor")]);
        // bovenste deuren
        if (furniture.dimensions.topHeight > 0)
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("removable_panel_decor")]);
      } else if (furniture.doorType === DoorType.pushDoors) {
        // onderste deuren
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("soft_close_doors")]);
        // bovenste deuren
        if (furniture.dimensions.topHeight > 0)
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("soft_close_doors")]);
      }
      //bovenstel marineconform
      if (
        furniture.dimensions.topHeight < 75 &&
        furniture.dimensions.topHeight > 0
      )
        totalPrice +=
          ((2 * aquariumDimensions.depth * furniture.dimensions.topHeight +
            aquariumDimensions.depth * aquariumDimensions.width +
            aquariumDimensions.width * furniture.dimensions.topHeight +
            aquariumDimensions.width * upperBottomPlankStripDepth) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
      else if (furniture.dimensions.topHeight >= 75)
        totalPrice +=
          ((2 * aquariumDimensions.depth * furniture.dimensions.topHeight +
            ((getAmountCompartiments(aquariumDimensions.width) - 1) *
              aquariumDimensions.depth *
              furniture.dimensions.topHeight) /
              2 +
            2 * aquariumDimensions.depth * aquariumDimensions.width +
            aquariumDimensions.width * furniture.dimensions.topHeight +
            aquariumDimensions.width * upperBottomPlankStripDepth) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
      //seethrough
      if (furniture.seeThrough) {
        totalPrice +=
          (((furniture.dimensions.baseHeight + outerPlankOverlap) *
            aquariumDimensions.width) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
        if (furniture.dimensions.topHeight > 0) {
          totalPrice +=
            (((furniture.dimensions.topHeight + outerPlankOverlap) *
              aquariumDimensions.width) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
        }
      }
      //electraruimte
      if (
        furniture.electraRuimte === ElectraRuimte.left ||
        furniture.electraRuimte === ElectraRuimte.right
      ) {
        totalPrice +=
          ((furniture.dimensions.baseHeight * aquariumDimensions.depth +
            electraPlateWidth * furniture.dimensions.baseHeight) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("electra_closet")]);
          if (furniture.aquaswitch)
            totalPrice += Number(getPrices()["price"][getPriceIndex("aquaswitch")]);
      }
      return totalPrice;
    }
    case FurnitureType.marine: {
      let totalPrice = 0;
      //onderverdieping
      //sidepanels
      totalPrice +=
        ((2 *
          aquariumDimensions.depth *
          (furniture.dimensions.baseHeight + outerPlankOverlap)) /
          10000) *
        Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
      //doors
      totalPrice +=
        ((aquariumDimensions.width *
          (furniture.dimensions.baseHeight + outerPlankOverlap)) /
          10000) *
        Number(getPrices()["price"][getPriceIndex("soft_close_doors")]);
      //inside
      totalPrice +=
        (((getAmountCompartiments(aquariumDimensions.width) + 1) *
          aquariumDimensions.depth *
          furniture.dimensions.baseHeight +
          3 * aquariumDimensions.depth * aquariumDimensions.width +
          aquariumDimensions.width * furniture.dimensions.baseHeight) /
          10000) *
        Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
      //bovenverdieping
      if (furniture.dimensions.topHeight > 0) {
        //sidepanels
        totalPrice +=
          ((2 *
            aquariumDimensions.depth *
            (furniture.dimensions.topHeight + outerPlankOverlap)) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
        //doors
        totalPrice +=
          ((aquariumDimensions.width *
            (furniture.dimensions.topHeight + outerPlankOverlap)) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("soft_close_doors")]);
        //inside
        if (
          furniture.dimensions.topHeight < 75 &&
          furniture.dimensions.topHeight > 0
        )
          totalPrice +=
            ((2 * aquariumDimensions.depth * furniture.dimensions.topHeight +
              aquariumDimensions.depth * aquariumDimensions.width +
              aquariumDimensions.width *
                (furniture.dimensions.topHeight) +
              aquariumDimensions.width * upperBottomPlankStripDepth) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
        else if (furniture.dimensions.topHeight >= 75)
          totalPrice +=
            ((2 * aquariumDimensions.depth * furniture.dimensions.topHeight +
              ((getAmountCompartiments(aquariumDimensions.width) - 1) *
                aquariumDimensions.depth *
                furniture.dimensions.topHeight) /
                2 +
              2 * aquariumDimensions.depth * aquariumDimensions.width +
              aquariumDimensions.width *
                (furniture.dimensions.topHeight) +
              aquariumDimensions.width * upperBottomPlankStripDepth) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("marine_conform_plastic")]);
      }
      //pootjes
      totalPrice +=
        (getAmountCompartiments(aquariumDimensions.width) + 1) *
        2 *
        Number(getPrices()["price"][getPriceIndex("marine_conform_leg")]);
      //seethrough
      if (furniture.seeThrough) {
        totalPrice +=
          ((aquariumDimensions.width *
            (furniture.dimensions.baseHeight + outerPlankOverlap)) /
            10000) *
          Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
        if (furniture.dimensions.topHeight > 0)
          totalPrice +=
            ((aquariumDimensions.width *
              (furniture.dimensions.topHeight + outerPlankOverlap)) /
              10000) *
            Number(getPrices()["price"][getPriceIndex("decor_panel_side")]);
      }
      return totalPrice;
    }
  }
  return 0;
}

function getFilterPrice(aquarium: Aquarium): number {
  switch (aquarium.filter.filterType) {
    case FilterOptions.biological: {
      return (
        (aquarium.aquariumDimensions.height / 100) *
        Number(getPrices()["price"][getPriceIndex("biological_filter")])
      );
    }
    case FilterOptions.bio: {
      let totalPrice = 0;
      //filterglas
      let filterWidth =
        aquarium.aquariumDimensions.width -
        getFilterWidthDiff(aquarium.aquariumDimensions.width);
      let bioFilterDepth = getBioFilterDepth(aquarium.aquariumDimensions.depth);
      let filterHeight = getBioFilterHeight(
        aquarium.furniture.dimensions.baseHeight
      );
      let totalsqmt =
        (filterWidth * bioFilterDepth +
          2 * filterWidth * filterHeight +
          2 * bioFilterDepth * filterHeight) /
        10000;
      totalsqmt += (5 * bioFilterDepth * filterHeight) / 10000;
      totalPrice +=
        totalsqmt * Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      //eventueel de overloop
      if (aquarium.filter.filterLocation === FilterLocation.midMid)
        totalPrice +=
          (aquarium.aquariumDimensions.height / 100) *
          Number(getPrices()["price"][getPriceIndex("overflow")]) *
          2;
      else
        totalPrice +=
          (aquarium.aquariumDimensions.height / 100) *
          Number(getPrices()["price"][getPriceIndex("overflow")]);
      //gaten
      totalPrice +=
        3 *
        Number(getPrices()["price"][getPriceIndex("hole_drilling")]) *
        getGlassWidth(
          aquarium.aquariumDimensions.width,
          aquarium.aquariumDimensions.height,
          aquarium.aquariumDimensions.depth,
          aquarium.aquariumType
        ) *
        10;
      //afdek systeem
      if (aquarium.filter.bioCoverPanels)
        totalPrice +=
          ((filterWidth * bioFilterDepth) / 10000) *
          Number(getPrices()["price"][getPriceIndex("bio_cover_system")]);
      //pvc buizen
      if (aquarium.extra.pvcPipeworkPackage === PvcPipeworkPackage.byAqualife) {
        totalPrice += Number(
          getPrices()["price"][getPriceIndex("pvc_package_sump_bio")]
        );
      }

      return totalPrice;
    }
    case FilterOptions.external: {
      let totalPrice = 0;
      //droogkamer
      if (aquarium.filter.externalDryroom)
        totalPrice +=
          (aquarium.aquariumDimensions.height / 100) *
          Number(getPrices()["price"][getPriceIndex("dryroom")]);
      //boringen+pvc buizen
      if (aquarium.filter.boreholeLeft) {
        totalPrice +=
          Number(getPrices()["price"][getPriceIndex("hole_drilling")]) *
          getGlassWidth(
            aquarium.aquariumDimensions.width,
            aquarium.aquariumDimensions.height,
            aquarium.aquariumDimensions.depth,
            aquarium.aquariumType
          ) *
          10;
        totalPrice += Number(getPrices()["price"][getPriceIndex("pvc_package")]);
      }
      if (aquarium.filter.boreholeRight) {
        totalPrice +=
          Number(getPrices()["price"][getPriceIndex("hole_drilling")]) *
          getGlassWidth(
            aquarium.aquariumDimensions.width,
            aquarium.aquariumDimensions.height,
            aquarium.aquariumDimensions.depth,
            aquarium.aquariumType
          ) *
          10;
        totalPrice += Number(getPrices()["price"][getPriceIndex("pvc_package")]);
      }
      return totalPrice;
    }
    case FilterOptions.sump: {
      let totalPrice = 0;
      //filterglas
      let filterWidth =
        aquarium.aquariumDimensions.width -
        getSumpFilterWidthDiff(aquarium.aquariumDimensions.width);
      let filterDepth =
        aquarium.aquariumDimensions.depth - sumpFilterDepthDiff <
        maxSumpFilterDepth
          ? aquarium.aquariumDimensions.depth - sumpFilterDepthDiff
          : maxSumpFilterDepth;
      let filterHeight = sumpFilterHeight;
      //left, right
      totalPrice +=
        ((2 * filterDepth * filterHeight) / 10000) *
        Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      //front, back
      totalPrice +=
        ((2 * filterHeight * filterWidth) / 10000) *
        Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      //bottom
      totalPrice +=
        ((filterWidth * filterDepth) / 10000) *
        Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      //tussenplaten
      totalPrice +=
        ((getSideCompWidth(aquarium.aquariumDimensions.width) * filterHeight) /
          10000) *
        Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      totalPrice +=
        ((filterDepth * filterHeight) / 10000) *
        Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      totalPrice +=
        ((2 * (filterHeight - bottomPlanksHeightDiff) * filterDepth) / 10000) *
        Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      //eventueel de overloop
      if (aquarium.filter.filterLocation === FilterLocation.midMid)
        totalPrice +=
          (aquarium.aquariumDimensions.height / 100) *
          Number(getPrices()["price"][getPriceIndex("overflow")]) *
          2;
      else if (aquarium.filter.filterLocation != null)
        totalPrice +=
          (aquarium.aquariumDimensions.height / 100) *
          Number(getPrices()["price"][getPriceIndex("overflow")]);
      //osmose vak
      if (aquarium.filter.osmoseCompartiment)
        totalPrice +=
          ((filterDepth * filterHeight) / 10000) *
          Number(getPrices()["price"][getPriceIndex("sump_glass")]);
      //gaten, pvc buizen
      if (aquarium.filter.filterLocation != null) {
        totalPrice +=
          3 *
          Number(getPrices()["price"][getPriceIndex("hole_drilling")]) *
          getGlassWidth(
            aquarium.aquariumDimensions.width,
            aquarium.aquariumDimensions.height,
            aquarium.aquariumDimensions.depth,
            aquarium.aquariumType
          )*10;

        if (
          aquarium.extra.pvcPipeworkPackage === PvcPipeworkPackage.byAqualife
        ) {
          totalPrice += Number(
            getPrices()["price"][getPriceIndex("pvc_package_sump_bio")]
          );
        }
      }

      return totalPrice;
    }
  }
  return 0;
}

function getBioFilterDepth(aquariumDepth: number): number {
  if (aquariumDepth - filterDepthDiff < maxFilterDepth) {
    return aquariumDepth - filterDepthDiff;
  } else {
    return maxFilterDepth;
  }
}

function getBioFilterHeight(closetHeight: number): number {
  if (closetHeight === 0) {
    return biggerFilterHeight;
  } else if (closetHeight < closetHeightdisappear && closetHeight !== 0) {
    return 0;
  }

  if (closetHeight < closetHeightchanger && closetHeight !== 0) {
    return smallerFilterHeight;
  } else {
    return biggerFilterHeight;
  }
}

//checked
function getTypePrice(
  aquariumDimensions: AquariumDimensions,
  aquarium: Aquarium
): number {
  switch (aquarium.aquariumType) {
    case AquariumType.fullGlass: {
      return (
        getStripPrice(aquariumDimensions) +
        getGlassPanelPrice(aquarium.fullGlassCoverPanels, aquariumDimensions) +
        (((Number(aquarium.grindlist) *
          Number(getPrices()["price"][getPriceIndex("grindlist")]) *
          aquariumDimensions.depth) /
          100) *
          aquariumDimensions.width) /
          100 //widthstrip+lengthstrips+cover of aquarium
      );
    }
    case AquariumType.lightCover: {
      return (
        getStripPrice(aquariumDimensions) +
        getLightCoverPrice(true, aquarium.coverPanels, aquariumDimensions) +
        (((Number(aquarium.grindlist) *
          Number(getPrices()["price"][getPriceIndex("grindlist")]) *
          aquariumDimensions.depth) /
          100) *
          aquariumDimensions.width) /
          100
      );
    }
    case AquariumType.rimless: {
      return (
        (((Number(aquarium.grindlist) *
          Number(getPrices()["price"][getPriceIndex("grindlist")]) *
          aquariumDimensions.depth) /
          100) *
          aquariumDimensions.width) /
        100
      );
    }
    case AquariumType.turtle: {
      //schildpad eiland
      //lichtbak
      //afdekpanelen + gaatjes
      if (aquarium.lightCover) {
        return (
          getStripPrice(aquariumDimensions) +
          getLightCoverPrice(
            aquarium.lightCover,
            aquarium.coverPanels,
            aquariumDimensions
          ) +
          turtleIslandPrice(aquarium.turtleIsland, aquariumDimensions) +
          (((Number(aquarium.grindlist) *
            Number(getPrices()["price"][getPriceIndex("grindlist")]) *
            aquariumDimensions.depth) /
            100) *
            aquariumDimensions.width) /
            100
        );
      }
      return (
        getStripPrice(aquariumDimensions) +
        turtleIslandPrice(aquarium.turtleIsland, aquariumDimensions) +
        (((Number(aquarium.grindlist) *
          Number(getPrices()["price"][getPriceIndex("grindlist")]) *
          aquariumDimensions.depth) /
          100) *
          aquariumDimensions.width) /
          100
      );
    }
  }
  return 0;
}

function getStripPrice(aquariumDimensions: AquariumDimensions): number {
  let totalPrice = 0;
  let lengthstripPrice = Number(getPrices()["price"][getPriceIndex("lengthstrips")]);
  let widthstripPrice = Number(getPrices()["price"][getPriceIndex("widthstrips")]);
  if (
    aquariumDimensions.width > minLengthstripWidth ||
    aquariumDimensions.depth > minLengthstripDepth
  ) {
    totalPrice += (2 * lengthstripPrice * aquariumDimensions.width) / 100;
  }
  if (aquariumDimensions.depth > minSideWithstripDepth) {
    totalPrice += (2 * lengthstripPrice * aquariumDimensions.depth) / 100;
  }

  totalPrice +=
    (widthstripPrice *
      getAmountWidthStrips(aquariumDimensions.width, aquariumDimensions.depth) *
      aquariumDimensions.depth) /
    100;
  return totalPrice;
}

//TODO bereken prijs van eiland
function turtleIslandPrice(
  turtleIsland: TurtleIsland | null,
  aquariumDimensions: AquariumDimensions
): number {
  if (
    turtleIsland === TurtleIsland.left ||
    turtleIsland === TurtleIsland.right
  ) {
    return (
      ((aquariumDimensions.depth * platformWidth * scale +
        aquariumDimensions.depth * platformHeight * scale +
        bridgeWidthDepth * scale * bridgeWidthDepth * scale) /
        10000) *
      Number(getPrices()["price"][getPriceIndex("turtle_island_glass")])
    );
  } else if (turtleIsland === TurtleIsland.twosided) {
    return (
      ((2 *
        (aquariumDimensions.depth * platformWidth * scale +
          aquariumDimensions.depth * platformHeight * scale +
          bridgeWidthDepth * scale * bridgeWidthDepth * scale)) /
        10000) *
      Number(getPrices()["price"][getPriceIndex("turtle_island_glass")])
    );
  }
  return 0;
}

function getLightCoverPrice(
  lightCover: boolean,
  coverPanels: boolean,
  aquariumDimensions: AquariumDimensions
): number {
  let totalPrice = 0;
  if (lightCover) {
    totalPrice +=
      ((2 * aquariumDimensions.width + 2 * aquariumDimensions.depth) / 100) *
      Number(getPrices()["price"][getPriceIndex("lightcover_aluminium")]);
  } else {
    // TODO there is no decor lightcover
    //totalPrice += (2*aquariumDimensions.width+2*aquariumDimensions.depth)/100*Number(getPrices()["price"][getPriceIndex("lightcover_decor")])
  }
  if (coverPanels) {
    let amountlids = 1;
    for (let i = 0; i < lidwidths.length; i++) {
      if (aquariumDimensions.width > lidwidths[i]) {
        amountlids += 1;
      }
    }
    totalPrice +=
      (aquariumDimensions.width *
        aquariumDimensions.depth *
        Number(getPrices()["price"][getPriceIndex("coverplate")])) /
      10000;
    totalPrice +=
      amountlids * Number(getPrices()["price"][getPriceIndex("reliefhole")]);
  }

  return totalPrice;
}

function getGlassPanelPrice(
  fullGlassPanels: FullGlassCoverPanels | null,
  aquariumDimensions: AquariumDimensions
): number {
  if (fullGlassPanels === FullGlassCoverPanels.nonSliding) {
    let widthStripsSqm =
      (getAmountWidthStrips(
        aquariumDimensions.width,
        aquariumDimensions.depth
      ) *
        widthstripsDepth *
        aquariumDimensions.depth) /
      10000;
    if (
      (aquariumDimensions.width * aquariumDimensions.depth) / 10000 <
      coverGlassMinimumSqm
    ) {
      return (
        Number(getPrices()["price"][getPriceIndex("coverglass_non_sliding")]) *
          ((aquariumDimensions.width * aquariumDimensions.depth) / 10000 -
            widthStripsSqm) +
        20
      );
    }
    return (
      Number(getPrices()["price"][getPriceIndex("coverglass_non_sliding")]) *
      ((aquariumDimensions.width * aquariumDimensions.depth) / 10000 -
        widthStripsSqm)
    );
  } else if (fullGlassPanels === FullGlassCoverPanels.sliding) {
    let amountWidthStrips = getAmountWidthStrips(
      aquariumDimensions.width,
      aquariumDimensions.depth
    );
    let widthStripsSqm =
      (amountWidthStrips * widthstripsDepth * aquariumDimensions.depth) / 10000;
    if (
      (aquariumDimensions.width * aquariumDimensions.depth) / 10000 <
      coverGlassMinimumSqm
    ) {
      return (
        Number(getPrices()["price"][getPriceIndex("coverglass_sliding")]) *
          ((aquariumDimensions.width * aquariumDimensions.depth) / 10000 -
            widthStripsSqm) +
        (amountWidthStrips + 1) *
          2 *
          Number(getPrices()["price"][getPriceIndex("pvc_strips")]) +
        20
      );
    }
    return (
      Number(getPrices()["price"][getPriceIndex("coverglass_sliding")]) *
        ((aquariumDimensions.width * aquariumDimensions.depth) / 10000 -
          widthStripsSqm) +
      (amountWidthStrips + 1) *
        2 *
        Number(getPrices()["price"][getPriceIndex("pvc_strips")])
    );
  }
  return 0;
}

// glass price
// grind price
// paint price
// checked, is correct
function calculateAquariumGlassPrice(
  aquariumDimensions: AquariumDimensions,
  sideInfo: SidesInfo,
  aquariumType: AquariumType | null,
  sharpenGlass: boolean,
  waterproofPlate: boolean
): number {
  if (aquariumType === null) {
    aquariumType = AquariumType.rimless;
  }
  let totalPrice = 0;
  let glassWidth = getGlassWidth(
    aquariumDimensions.width,
    aquariumDimensions.height,
    aquariumDimensions.depth,
    aquariumType
  );
  //glass price
  totalPrice += getAquariumSidesPrice(
    aquariumDimensions,
    sideInfo,
    getGlassPrice(glassWidth, true),
    getGlassPrice(glassWidth, false)
  );

  // paint price
  totalPrice += getPaintPrice(
    aquariumDimensions,
    sideInfo,
    Number(getPrices()["price"][getPriceIndex("painting")])
  );
  // grind price(slijpen)
  let grindPrice = Number(getPrices()["price"][getPriceIndex("glass_grinding")]);
  //always grind the sides
  totalPrice += ((4 * aquariumDimensions.height) / 100) * grindPrice;
  if (sharpenGlass) {
    //grind the upper glasssides
    totalPrice +=
      ((2 * aquariumDimensions.depth + 2 * aquariumDimensions.width) / 100) *
      grindPrice;
  }

  //betonplexplaat onder aquarium
  if (
    ((((aquariumDimensions.width / 10) * aquariumDimensions.height) / 10) *
      aquariumDimensions.depth) /
      10 >
      betonplexlimit &&
    waterproofPlate
  ) {
    totalPrice +=
      ((aquariumDimensions.width * aquariumDimensions.depth) / 10000) *
      Number(getPrices()["price"][getPriceIndex("betonplex_18mm")]);
  } else if (waterproofPlate) {
    totalPrice +=
      ((aquariumDimensions.width * aquariumDimensions.depth) / 10000) *
      Number(getPrices()["price"][getPriceIndex("betonplex_9mm")]);
  }
  return totalPrice;
}

function getPaintPrice(
  aquariumDimensions: AquariumDimensions,
  sides: SidesInfo,
  paintPrice: number
): number {
  let totalPrice = 0;
  let sqmLeftRight =
    (aquariumDimensions.height * aquariumDimensions.depth) / 10000;
  let sqmFrontBack =
    (aquariumDimensions.width * aquariumDimensions.height) / 10000;
  if (sides.back.colorPainted) {
    totalPrice += sqmFrontBack * paintPrice;
  }
  if (sides.left.colorPainted) {
    totalPrice += sqmLeftRight * paintPrice;
  }
  if (sides.right.colorPainted) {
    totalPrice += sqmLeftRight * paintPrice;
  }
  if (sides.front.colorPainted) {
    totalPrice += sqmFrontBack * paintPrice;
  }
  return totalPrice;
}

function getAquariumSidesPrice(
  aquariumDimensions: AquariumDimensions,
  sides: SidesInfo,
  glassPriceOptiWhite: number,
  glassPrice: number
): number {
  let totalPrice = 0;
  let sqmLeftRight =
    (aquariumDimensions.height * aquariumDimensions.depth) / 10000;
  let sqmFrontBack =
    (aquariumDimensions.width * aquariumDimensions.height) / 10000;
  let sqmBottom = (aquariumDimensions.depth * aquariumDimensions.width) / 10000;
  let normalSides = [true, true, true, true];
  if (sides.front.optiWhite) {
    normalSides[0] = false;
    totalPrice += sqmFrontBack * glassPriceOptiWhite;
  }
  if (sides.right.optiWhite) {
    normalSides[1] = false;
    totalPrice += sqmLeftRight * glassPriceOptiWhite;
  }
  if (sides.left.optiWhite) {
    normalSides[2] = false;
    totalPrice += sqmLeftRight * glassPriceOptiWhite;
  }
  if (sides.back.optiWhite) {
    normalSides[3] = false;
    totalPrice += sqmFrontBack * glassPriceOptiWhite;
  }

  if (normalSides[0] === true) {
    totalPrice += sqmFrontBack * glassPrice;
  }
  if (normalSides[1] === true) {
    totalPrice += sqmLeftRight * glassPrice;
  }
  if (normalSides[2] === true) {
    totalPrice += sqmLeftRight * glassPrice;
  }
  if (normalSides[3] === true) {
    totalPrice += sqmFrontBack * glassPrice;
  }
  totalPrice += glassPrice * sqmBottom;
  return totalPrice;
}

function getGlassPrice(glassWidth: number, optiWhite: boolean): number {
  switch (glassWidth) {
    case 0.4: {
      if (optiWhite) {
        return 0;
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_4mm")]);
      }
    }
    case 0.6: {
      if (optiWhite) {
        return 0;
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_6mm")]);
      }
    }
    case 0.8: {
      if (optiWhite) {
        return Number(getPrices()["price"][getPriceIndex("optiwhite_glass_8mm")]);
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_8mm")]);
      }
    }
    case 1.0: {
      if (optiWhite) {
        return Number(getPrices()["price"][getPriceIndex("optiwhite_glass_10mm")]);
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_10mm")]);
      }
    }
    case 1.2: {
      if (optiWhite) {
        return Number(getPrices()["price"][getPriceIndex("optiwhite_glass_12mm")]);
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_12mm")]);
      }
    }
    case 1.5: {
      if (optiWhite) {
        return Number(getPrices()["price"][getPriceIndex("optiwhite_glass_15mm")]);
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_15mm")]);
      }
    }
    case 1.9: {
      if (optiWhite) {
        return Number(getPrices()["price"][getPriceIndex("optiwhite_glass_19mm")]);
      } else {
        return Number(getPrices()["price"][getPriceIndex("glass_19mm")]);
      }
    }
  }
  return 0;
}

//check in the jsonfile how many widthstrips are needed with a certain width and depth
function getAmountWidthStrips(width: number, depth: number) {
  //check where depth is at (between which values is depth)
  let horizontalIndex = getWidthStripValues()["horizontalValues"].length-1;
  for (let i = 0; i < getWidthStripValues()["horizontalValues"].length; i++) {
    if (depth < Number(getWidthStripValues()["horizontalValues"][i])) {
      horizontalIndex = i + 1;
      break;
    }
  }

  //check where width is at (between which values is width)
  let verticalIndex = getWidthStripValues()["verticalValues"].length;
  for (let i = 0; i < getWidthStripValues()["verticalValues"].length; i++) {
    if (width < Number(getWidthStripValues()["verticalValues"][i])) {
      verticalIndex = i + 1;
      break;
    }
  }

  //check how many widthstrips there should be
  for (let i = 0; i < getWidthStripValues()["amount_strips"].length; i++) {
    if (
      verticalIndex === Number(getWidthStripValues()["amount_strips"][i][0]) &&
      horizontalIndex === Number(getWidthStripValues()["amount_strips"][i][1])
    ) {
      return Number(getWidthStripValues()["amount_strips"][i][2]);
    }
  }
  return 0;
}

function getPriceIndex(thing: string): number {
  for (let i = 0; i < getPrices()["thing"].length; i++) {
    if (getPrices()["thing"][i] === thing) {
      return i;
    }
  }
  return -1;
}

function getGlassWidth(
  width: number,
  height: number,
  depth: number,
  aquariumType: AquariumType | null
): number {
  if (aquariumType === null) {
    return 0;
  }
  let thicknessJSON = getGlassThicknessJSON(aquariumType);
  let horizontalIndex = -1;
  for (let i = 0; i < thicknessJSON["horizontalValues"].length; i++) {
    if (height < Number(thicknessJSON["horizontalValues"][i])) {
      horizontalIndex = i - 1;
      break;
    }
  }
  //if the height is bigger than the biggest value than it should be set to that index
  if (horizontalIndex === -1) {
    horizontalIndex = thicknessJSON["horizontalValues"].length - 1;
  }

  //searches for the index of the width or depth
  let verticalIndex = -1;
  for (let i = 0; i < thicknessJSON["verticalValues"].length; i++) {
    if (
      width < Number(thicknessJSON["verticalValues"][i]) &&
      depth < Number(thicknessJSON["verticalValues"][i])
    ) {
      verticalIndex = i - 1;
      break;
    }
  }
  //if the width or depth is bigger than the biggest value than it should be set to that index
  if (verticalIndex === -1) {
    verticalIndex = thicknessJSON["horizontalValues"].length - 1;
  }
  // the thicknessvalues consist of the vertical index, the horizontal index and the thickness of the glass in mm, search for the right indices and return the thickness
  for (let i = 0; i < thicknessJSON["thicknessValues"].length; i++) {
    if (
      Number(thicknessJSON["thicknessValues"][i][0]) === horizontalIndex &&
      Number(thicknessJSON["thicknessValues"][i][1]) === verticalIndex
    ) {
      return Number(thicknessJSON["thicknessValues"][i][2]) / 10;
    }
  }
  //if nothing was found, which should be impossible, nothing has to be changed because then the program will be broken somewhere else
  return 0;
}

//if type changes, change the JSONfile to the right type
//JSON files should already be loaded in before everything starts because glassthickness is an important thing
function getGlassThicknessJSON(type: string): Dict {
  switch (type) {
    case "rimless": {
      return rimlessThickness;
    }
    case "fullglass": {
      return fullglassThickness;
    }
    case "lightcover": {
      return lightcoverThickness;
    }
    case "turtle": {
      return turtleThickness;
    }
  }
  return {};
  /*var xhr = new XMLHttpRequest();
      xhr.open('POST', 'glassThiknesses.php', true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  
      xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status === 200) {
              var jsonResponse = JSON.parse(xhr.responseText);
              console.log(jsonResponse);
          }
      };
      xhr.send("type="+type);*/
}

export const getAquariumGlassPrice = (aquarium: Aquarium) => {
  let totalPrice = calculateAquariumGlassPrice(
    aquarium.aquariumDimensions,
    aquarium.aquariumSides,
    aquarium.aquariumType,
    aquarium.sharpenGlass,
    aquarium.extra.waterproofPlate
  );
  let marge = totalPrice * Number(getPrices()["price"][getPriceIndex("marge")]);
  //let btw = totalPrice * Number(getPrices()["price"][getPriceIndex("btw")]);
  return totalPrice + marge// + btw;
};
export const getAquariumTypePrice = (aquarium: Aquarium) => {
  let totalPrice = getTypePrice(aquarium.aquariumDimensions, aquarium);
  let marge = totalPrice * Number(getPrices()["price"][getPriceIndex("marge")]);
  //let btw = totalPrice * Number(getPrices()["price"][getPriceIndex("btw")]);
  return totalPrice + marge// + btw;
};
export const getAquariumFilterPrice = (aquarium: Aquarium) => {
  let totalPrice = getFilterPrice(aquarium);
  let marge = totalPrice * Number(getPrices()["price"][getPriceIndex("marge")]);
  //let btw = totalPrice * Number(getPrices()["price"][getPriceIndex("btw")]);
  return totalPrice + marge// + btw;
};
export const getAquariumFurniturePrice = (aquarium: Aquarium) => {
  let totalPrice = getFurniturePrice(
    aquarium.aquariumDimensions,
    aquarium.furniture,
    aquarium.furniture.furnitureType
  );
  let marge = totalPrice * Number(getPrices()["price"][getPriceIndex("marge")]);
  //let btw = totalPrice * Number(getPrices()["price"][getPriceIndex("btw")]);
  return totalPrice + marge// + btw;
};

/*
export type SideInfo = {
  optiWhite: boolean;
  side: Side;
  aquariumDimensions: AquariumDimensions;//why is this here, dimensions are way more important, is should be used on more places than only here
  sideGlassColor: SideGlassColor;
};

 */
/*
export type AquariumCalculatorNeeds = {
  aquariumDimensions: AquariumDimensions;
  
  aquariumType: AquariumType;
  rimless: Rimless;
  lightCover: LightCover;
  fullGlass: FullGlass;
  turtle: Turtle;
  sideInfo: SideInfo;

  filterOptions: FilterOptions;
  externalFilter: ExternalFilter;
  biologicalFilter: BiologicalFilter;
  biofilter: BioFilter;
  sumpFilter: SumpFilter;

  furnitureDimensions: furnitureDimensions;
  furniturtype: FurnitureType;
  meubelOptions: MeubelOpties;
}
 */
