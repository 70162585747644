import { configureStore } from "@reduxjs/toolkit";
import languageReducer from "./languageSlice";
import aquariumReducer from "./aquariumSlice";
import configuratorReducer from "./configuratorSlice";
import userReducer from "./userSlice";
import dashboardReducer from "./dashboardSlice";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import { Aquarium } from "../@types/aquarium";
import { updateDrawing } from "../utils/aquarium";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["aquarium", "configurator", "language"],
};

const reducer = combineReducers({
  language: languageReducer,
  aquarium: aquariumReducer,
  configurator: configuratorReducer,
  user: userReducer,
  dashboard: dashboardReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

let currentAquarium: Aquarium;
export const checkNewAquariumDrawing = (ignoreNewAquarium: boolean) => {
  console.log("checkNewAquariumDrawing");
  let previousAquarium = currentAquarium;
  currentAquarium = store.getState().aquarium;
  if (previousAquarium !== currentAquarium || ignoreNewAquarium) {
    const dispatch = store.dispatch;
    updateDrawing(currentAquarium, dispatch);
  }
};

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
