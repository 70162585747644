import { UnknownAction } from "redux";
import { CheckRole, LoginUser } from "../../@types/api";
import { ProductData } from "../../@types/product";
import { SuperUser } from "../../@types/user";
import {
  setCheckRole,
  setFilterWidthInfo,
  setGlassThiknesses,
  setPrices,
  setWidthStripValues,
} from "../../redux/configuratorSlice";
import { AquariumType } from "../../@types/aquarium";
import { setProducts } from "../../redux/dashboardSlice";

//DONE
export const saveUserToDatabase = (user: SuperUser) => {
  const request =
    'newUser={"firstname":' +
    `"${user.firstname}", "lastname":"${user.lastname}", "company":"${user.company}","password":"${user.password}","email":"${user.email}","role":"${user.role}"}`;
  const cleanRequest = request.replaceAll("&", "");

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "addUser.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
    }
  };
  xhr.send(cleanRequest);
};

//TODO: moeten nog nakijken hoe we images opslaan
export const saveProductToDatabase = (product: ProductData) => {
  const request =
    'ProductData={"id":' +
    `"${product.id}", "name":"${product.name}", "description":"${product.description}","category":"${product.category}","price":"${product.price}","discountReseller1":"${product.discountReseller1}","discountReseller2":"${product.discountReseller2}","pricePerUnit":"${product.pricePerUnit}","isRecommanded":"${product.isRecommanded}","recommandedText":"${product.recommandedText}","image":"${product.image}","dimensionsDependant":"${product.dimensionsDependant}","volumeDependant":"${product.volumeDependant}"}`;
  const cleanRequest = request.replaceAll("&", "");
  console.log(cleanRequest);
  var xhr = new XMLHttpRequest();
  xhr.open("PUT", "setProductData.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      console.log(xhr.responseText);
    }
  };
  xhr.send(cleanRequest);
};

//DONE
export const getProductDataFromDatabase = (
  dispatch: React.Dispatch<UnknownAction>
) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "getProductData.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse: ProductData[] = JSON.parse(xhr.responseText);
        dispatch(setProducts(jsonResponse));
      } catch (error) {
        console.error(error);
      }
    }
  };
  xhr.send();
};

//TODO DAAN: deze route aanmaken in de backend
// export const getSuperUsersFromDatabase = (): SuperUser[] => {
//   var xhr = new XMLHttpRequest();
//   xhr.open("GET", "TODO.php", true);
//   xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

//   xhr.onreadystatechange = function () {
//     if (xhr.readyState === 4 && xhr.status === 200) {
//       try {
//         let jsonResponse: ProductData[] = JSON.parse(xhr.responseText);
//         console.log(jsonResponse);
//         return jsonResponse;
//       } catch (error) {
//         console.log(error);
//       }
//     }
//     return [];
//   };
//   xhr.send();
//   return [];
// };

//DONE
export const updateFilterWidthInfoFromDatabase = (
  dispatch: React.Dispatch<UnknownAction>
) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "filterWidthInfo.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        dispatch(setFilterWidthInfo(jsonResponse));
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};

//TODO: testen
export const updateGlassThicknessesFromDatabase = (
  aquariumType: AquariumType,
  dispatch: React.Dispatch<UnknownAction>
) => {
  const request = '{"type":' + `"${aquariumType}"}`;
  console.log(request);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "glassThiknesses.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        console.log(jsonResponse);
        dispatch(setGlassThiknesses(jsonResponse));
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send(request);
};

//DONE
export const updatePricesFromDatabase = (
  dispatch: React.Dispatch<UnknownAction>
) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "prices.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        dispatch(setPrices(jsonResponse));
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};
//DONE
export const updateWidthStripsFromDatabase = (
  dispatch: React.Dispatch<UnknownAction>
) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "widthStrips.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse = JSON.parse(xhr.responseText);
        dispatch(setWidthStripValues(jsonResponse));
      } catch (error) {
        console.log(error);
      }
    }
  };
  xhr.send();
};

const isLoginUser = (item: LoginUser | any): item is LoginUser => {
  return typeof item !== "undefined";
};

//TODO: resellers testen
export const loginUser = async (
  email: string,
  password: string,
  setValue: React.Dispatch<React.SetStateAction<any>>
) => {
  const request = 'user={"email":' + `"${email}", "password":"${password}"}`;
  const cleanRequest = request.replaceAll("&", "");

  var xhr = new XMLHttpRequest();
  xhr.open("POST", "login.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        console.log(JSON.parse(xhr.responseText));
        if (isLoginUser(JSON.parse(xhr.responseText))) {
          setValue(JSON.parse(xhr.responseText));
        } else {
          setValue(null);
        }
      } catch (error) {
        console.log(error);
      }
    }
    return null;
  };
  xhr.send(cleanRequest);
};

const isCheckRole = (item: CheckRole | any): item is CheckRole => {
  return typeof item !== "undefined";
};

// DONE
export const checkRole = (dispatch: React.Dispatch<UnknownAction>) => {
  var xhr = new XMLHttpRequest();
  xhr.open("GET", "checkRole.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      try {
        let jsonResponse: CheckRole = JSON.parse(xhr.responseText.toString());
        if (isCheckRole(jsonResponse)) {
          dispatch(setCheckRole(jsonResponse.role));
          return;
        } else {
          dispatch(setCheckRole(null));
          return;
        }
      } catch (error) {
        console.error(error);
        dispatch(setCheckRole(null));
        return;
      }
    }
  };
  xhr.send();
};

//TODO: testen
export const updateProductData = (updatedItem: ProductData) => {
  const request =
    'row={"id":' +
    `"${updatedItem.id}", "price":${updatedItem.price}, "discount_reseller1": ${updatedItem.discountReseller1}, "discount_reseller2": ${updatedItem.discountReseller2}, isRecommanded": ${updatedItem.isRecommanded}}`;
  console.log("REQUEST: ", request);
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "changeProductPrices.php", true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");

  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      console.log(xhr.responseText);
    }
  };
  xhr.send(request);
};
