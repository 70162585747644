import * as THREE from "three";
import { colorPlankSide, makeCircle, makePlank, makeRectangle } from "./helper";
import { TurtleIsland } from "../../@types/aquarium";
import { DecorTexture } from "../../@types/textures";
import { store } from "../../redux/store";

const getWidthStripValues = () => {
  return store.getState().configurator.widthStripValues;
};

const scale = 25;
const PI = 3.14159265359;

const lengthstripsDepth = 5;
const lengthstripsHeight = 1;
const widthstripsDepth = 12;
const widthstripsHeight = 1;
const lightcoverDiff = 4;
const coverHeight = 10;
const lidHeight = 2;
const fingerholediameter = 2;
const fingerholediff = 5; //how far the fingerhole is from the side
const griddiameter = 6;

const turtleGlassWidth = 0.8 / scale;
export const platformWidth = 25 / scale;
export const platformHeight = 8 / scale;
export const bridgeWidthDepth = 20 / scale; //the bridge is a square

const minWidth = 60;
const minDepth = 60;
const minSideWithstripDepth = 70;

//TODO zet dit in de database en haal dit op adhv een json bestand
const lidwidths = [60, 120, 180, 240, 260, 300, 360];

let currWidth: number;
let currHeight: number;
let currDepth: number;
let glassWidth: number;
let amountWidthStrips = 0;
let amountlids = 0;

let lightCoverPresent = false; //we want no lightcover in the beginning, but makeTurtleAquarium is called twice, so to get this on false we put it on false in the first place
let coverPanelsPresent = false; //the coverpanels are on when makeTurtleAquarium is called, that's why this is true

// draws an aquarium with turtle option
export function makeTurtleAquarium(
  scene: THREE.Scene,
  width: number,
  height: number,
  depth: number,
  newGlassWidth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //delete the planks from the past aquariumtype
  for (let i = aquariumTypePlanks.length - 1; i >= 0; i--) {
    scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
  amountWidthStrips = getAmountWidthStrips(width, depth);

  // amount coverpanels
  amountlids = 1;
  for (let i = 0; i < lidwidths.length; i++) {
    if (width > lidwidths[i]) {
      amountlids += 1;
    }
  }

  let lengthstripsHeightdiff =
    widthstripsHeight / scale + lengthstripsHeight / scale / 2;

  //lightcover
  aquariumTypePlanks[0] = makePlank(
    scene,
    0,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    depth / scale / 2 + 0.01,
    width / scale + 0.02,
    coverHeight / scale,
    0.01,
    0xeeeeee
  ); //front
  aquariumTypePlanks[1] = makePlank(
    scene,
    width / scale / 2 + 0.01,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    0,
    0.01,
    coverHeight / scale,
    depth / scale + 0.02,
    0xeeeeee
  ); //right
  aquariumTypePlanks[2] = makePlank(
    scene,
    0,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    -depth / scale / 2 - 0.01,
    width / scale + 0.02,
    coverHeight / scale,
    0.01,
    0xeeeeee
  ); //back
  aquariumTypePlanks[3] = makePlank(
    scene,
    -width / scale / 2 - 0.01,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    0,
    0.01,
    coverHeight / scale,
    depth / scale + 0.02,
    0xeeeeee
  ); //left

  //length strips
  aquariumTypePlanks[4] = colorPlankSide(
    scene,
    0,
    height / (scale * 2) - lengthstripsHeightdiff - lightcoverDiff / scale,
    depth / (scale * 2) - newGlassWidth - lengthstripsDepth / scale / 2,
    width / scale - 2 * newGlassWidth,
    lengthstripsHeight / scale,
    lengthstripsDepth / scale,
    [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0xdefcff],
    [0.3, 0.3, 0.3, 0.3, 1, 0.3]
  );
  aquariumTypePlanks[5] = colorPlankSide(
    scene,
    0,
    height / (scale * 2) - lengthstripsHeightdiff - lightcoverDiff / scale,
    -depth / (scale * 2) + newGlassWidth + lengthstripsDepth / scale / 2,
    width / scale - 2 * newGlassWidth,
    lengthstripsHeight / scale,
    lengthstripsDepth / scale,
    [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000],
    [0.3, 0.3, 0.3, 0.3, 0.3, 1]
  );

  //sidewidthstrips
  aquariumTypePlanks[6] = makePlank(
    scene,
    width / scale / 2 - newGlassWidth - lengthstripsDepth / scale / 2,
    height / (scale * 2) - newGlassWidth - lightcoverDiff / scale,
    0,
    lengthstripsDepth / scale,
    widthstripsHeight / scale,
    depth / scale - 2 * newGlassWidth,
    0xdefcff,
    0.3
  );
  aquariumTypePlanks[7] = makePlank(
    scene,
    -(width / scale / 2 - newGlassWidth - lengthstripsDepth / scale / 2),
    height / (scale * 2) - newGlassWidth - lightcoverDiff / scale,
    0,
    lengthstripsDepth / scale,
    widthstripsHeight / scale,
    depth / scale - 2 * newGlassWidth,
    0xdefcff,
    0.3
  );
  //turtle island left
  aquariumTypePlanks[8] = makePlank(
    scene,
    -width / scale / 2 + platformWidth / 2 + newGlassWidth,
    -platformHeight / 2,
    0,
    platformWidth,
    turtleGlassWidth,
    depth / scale - 2 * newGlassWidth,
    0xdefcff,
    0.5
  );
  aquariumTypePlanks[9] = makePlank(
    scene,
    -width / scale / 2 + platformWidth + newGlassWidth - turtleGlassWidth / 2,
    0,
    0,
    turtleGlassWidth,
    platformHeight - turtleGlassWidth,
    depth / scale - 2 * newGlassWidth,
    0xdefcff,
    0.5
  );
  aquariumTypePlanks[10] = makePlank(
    scene,
    -width / scale / 2 +
      platformWidth +
      newGlassWidth / 2 +
      7.07 / scale /*wortel(100/2)*/,
    -3.07 / scale /*4-wortel(100/2)*/,
    -depth / scale / 2 + bridgeWidthDepth / 2 + newGlassWidth,
    turtleGlassWidth,
    bridgeWidthDepth,
    bridgeWidthDepth,
    0xdefcff,
    0.5
  );
  aquariumTypePlanks[10].rotateZ(0.79);

  //turtle island right
  aquariumTypePlanks[11] = makePlank(
    scene,
    width / scale / 2 - platformWidth / 2 - newGlassWidth,
    -platformHeight / 2,
    0,
    platformWidth,
    turtleGlassWidth,
    depth / scale - 2 * newGlassWidth,
    0xdefcff,
    0.5
  );
  aquariumTypePlanks[12] = makePlank(
    scene,
    width / scale / 2 - platformWidth - newGlassWidth + turtleGlassWidth / 2,
    0,
    0,
    turtleGlassWidth,
    platformHeight - turtleGlassWidth,
    depth / scale - 2 * newGlassWidth,
    0xdefcff,
    0.5
  );
  aquariumTypePlanks[13] = makePlank(
    scene,
    width / scale / 2 -
      platformWidth -
      newGlassWidth / 2 -
      7.07 / scale /*wortel(100/2)*/,
    -3.07 / scale /*4-wortel(100/2)*/,
    -depth / scale / 2 + bridgeWidthDepth / 2 + newGlassWidth,
    turtleGlassWidth,
    bridgeWidthDepth,
    bridgeWidthDepth,
    0xdefcff,
    0.5
  );
  aquariumTypePlanks[13].rotateZ(-0.79);

  // make sure there isn't an island in the beginning
  changeTurtleIsland(scene, TurtleIsland.noIsland, aquariumTypePlanks);

  //widthstrips
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 14] = makePlank(
      scene,
      -width / scale / 2 +
        ((i + 1) * (width / scale - 2 * newGlassWidth)) /
          (amountWidthStrips + 1),
      height / (scale * 2) - newGlassWidth - lightcoverDiff / scale,
      0,
      widthstripsDepth / scale,
      widthstripsHeight / scale,
      depth / scale - 2 * newGlassWidth,
      0xdefcff,
      0.3
    );
  }

  //coverpanels
  for (let i = 0; i < amountlids; i++) {
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips] = makePlank(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      height / scale / 2 + lidHeight / scale / 2,
      0,
      width / scale / amountlids - 0.01,
      lidHeight / scale - 0.01,
      depth / scale - 0.01,
      0xdddddd
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      height / scale / 2 + lidHeight / scale + 0.001,
      depth / scale / 2 - fingerholediff / scale,
      fingerholediameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1].rotateX(
      (PI * 270) / 180
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      height / scale / 2 + lidHeight / scale + 0.001,
      0,
      griddiameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2].rotateX(
      (PI * 270) / 180
    );
  }

  // hide the strips when the width and depth isn't big enough
  stripsVisibleInvisible(scene, width, depth, aquariumTypePlanks);
  // make sure the lightcover isn't visible in the beginning

  coverPanelsPresent = true;
  changeTurtleCoverpanels(scene, aquariumTypePlanks, coverPanelsPresent);
  changeTurtleLightCover(scene, aquariumTypePlanks, lightCoverPresent);

  currDepth = depth;
  currWidth = width;
  currHeight = height;
  glassWidth = newGlassWidth;
}

//looks if lengthstrips must be visible, if not it makes them invisible
function stripsVisibleInvisible(
  scene: THREE.Scene,
  width: number,
  depth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  if (width > minWidth || depth > minDepth) {
    //add lengthstrips
    scene.add(aquariumTypePlanks[4]);
    scene.add(aquariumTypePlanks[5]);
  } else {
    //remove lengthstrips
    scene.remove(aquariumTypePlanks[4]);
    scene.remove(aquariumTypePlanks[5]);
  }

  if (depth > minSideWithstripDepth) {
    //add widthstrips on the sides of the aquarium
    scene.add(aquariumTypePlanks[6]);
    scene.add(aquariumTypePlanks[7]);
  } else {
    //remove widthstrips
    scene.remove(aquariumTypePlanks[6]);
    scene.remove(aquariumTypePlanks[7]);
  }
}

// change depth of aquarium
export function changeTurtleDepth(
  scene: THREE.Scene,
  depth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //put the coverlit and the lengthstrip in the right place
  aquariumTypePlanks[0].position.z += (depth - currDepth) / scale / 2;
  aquariumTypePlanks[1].geometry.scale(
    1,
    1,
    (depth / scale + 0.02) / (currDepth / scale + 0.02)
  );
  aquariumTypePlanks[2].position.z += (currDepth - depth) / scale / 2;
  aquariumTypePlanks[3].geometry.scale(
    1,
    1,
    (depth / scale + 0.02) / (currDepth / scale + 0.02)
  );

  //doesn't matter if they are drawn or not because stripsVisibleInvisible() makes sure they are visible when they should be
  //lengthstrips
  aquariumTypePlanks[4].position.z += (depth - currDepth) / scale / 2;
  aquariumTypePlanks[5].position.z += (currDepth - depth) / scale / 2;

  //width strips
  aquariumTypePlanks[6].geometry.scale(
    1,
    1,
    (depth / scale - 2 * glassWidth) / (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[7].geometry.scale(
    1,
    1,
    (depth / scale - 2 * glassWidth) / (currDepth / scale - 2 * glassWidth)
  );

  //left island
  aquariumTypePlanks[8].geometry.scale(
    1,
    1,
    (depth / scale - glassWidth * 2) / (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[9].geometry.scale(
    1,
    1,
    (depth / scale - glassWidth * 2) / (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[10].position.z =
    -depth / scale / 2 + bridgeWidthDepth / 2 + glassWidth;

  //rigth island
  aquariumTypePlanks[11].geometry.scale(
    1,
    1,
    (depth / scale - glassWidth * 2) / (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[12].geometry.scale(
    1,
    1,
    (depth / scale - glassWidth * 2) / (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[13].position.z = -(
    -depth / scale / 2 +
    bridgeWidthDepth / 2 +
    glassWidth
  );
  //make widthstrips longer
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 14].geometry.scale(
      1,
      1,
      (depth - 2 * glassWidth) / (currDepth - 2 * glassWidth)
    );
  }

  //make the coverpanels longer
  for (let i = 0; i < amountlids; i++) {
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips].geometry.scale(
      1,
      1,
      (depth / scale - 0.01) / (currDepth / scale - 0.01)
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1].position.z +=
      (depth - currDepth) / scale / 2;
  }
  let newAmountWidthStrips = getAmountWidthStrips(currWidth, depth);

  //if the amount of widthstrips is changed
  if (newAmountWidthStrips !== amountWidthStrips) {
    changeTurtleWidth(scene, currWidth, aquariumTypePlanks); //om ervoor te zorgen dat de breedtestrips goed zijn en omdat ik te lui ben om die code hierin te zetten
  }
  //make the lengthstrips invisible if the width is too small
  stripsVisibleInvisible(scene, currWidth, depth, aquariumTypePlanks);
  currDepth = depth;
}

export function changeTurtleWidth(
  scene: THREE.Scene,
  width: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //lightcover
  aquariumTypePlanks[0].geometry.scale(
    (width / scale + 0.02) / (currWidth / scale + 0.02),
    1,
    1
  );
  aquariumTypePlanks[1].position.x += (width - currWidth) / scale / 2;
  aquariumTypePlanks[2].geometry.scale(
    (width / scale + 0.02) / (currWidth / scale + 0.02),
    1,
    1
  );
  aquariumTypePlanks[3].position.x += (currWidth - width) / scale / 2;

  //lengthstrips
  aquariumTypePlanks[4].geometry.scale(
    (width / scale - 2 * glassWidth) / (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[5].geometry.scale(
    (width / scale - 2 * glassWidth) / (currWidth / scale - 2 * glassWidth),
    1,
    1
  );

  //widthstrips
  aquariumTypePlanks[6].position.x += (width - currWidth) / scale / 2;
  aquariumTypePlanks[7].position.x += (currWidth - width) / scale / 2;

  //left island
  aquariumTypePlanks[8].position.x =
    -width / scale / 2 + platformWidth / 2 + glassWidth;
  aquariumTypePlanks[9].position.x =
    -width / scale / 2 + platformWidth + glassWidth - turtleGlassWidth / 2;
  aquariumTypePlanks[10].position.x =
    -width / scale / 2 + platformWidth + glassWidth / 2 + 7.07 / scale;

  //right island
  aquariumTypePlanks[11].position.x = -(
    -width / scale / 2 +
    platformWidth / 2 +
    glassWidth
  );
  aquariumTypePlanks[12].position.x = -(
    -width / scale / 2 +
    platformWidth +
    glassWidth -
    turtleGlassWidth / 2
  );
  aquariumTypePlanks[13].position.x = -(
    -width / scale / 2 +
    platformWidth +
    glassWidth / 2 +
    7.07 / scale
  );

  //coverpanels
  let newAmountlids = 1;
  for (let i = 0; i < lidwidths.length; i++) {
    if (width > lidwidths[i]) {
      newAmountlids += 1;
    }
  }

  //widthstrips
  let newAmountWidthStrips = getAmountWidthStrips(width, currDepth);
  if (newAmountWidthStrips !== amountWidthStrips) {
    //if new amount of widthstrips delete everything after plank 13 (right island) and draw everything again
    amountWidthStrips = newAmountWidthStrips;
    for (let i = aquariumTypePlanks.length - 1; i >= 14; i--) {
      scene.remove(aquariumTypePlanks[i]);
      aquariumTypePlanks.splice(i);
    }
    //make widthstrips
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks[i + 14] = makePlank(
        scene,
        -width / scale / 2 +
          ((i + 1) * (width / scale - 2 * glassWidth)) /
            (amountWidthStrips + 1),
        currHeight / (scale * 2) -
          widthstripsHeight / scale -
          lightcoverDiff / scale,
        0,
        widthstripsDepth / scale,
        glassWidth,
        currDepth / scale - 2 * glassWidth,
        0xdefcff,
        0.3
      );
    }
    // make cover panels
    for (let i = 0; i < amountlids; i++) {
      aquariumTypePlanks[i * 3 + 14 + amountWidthStrips] = makePlank(
        scene,
        -width / scale / 2 +
          i * (width / scale / amountlids) +
          width / scale / amountlids / 2,
        currHeight / scale / 2 + lidHeight / scale / 2,
        0,
        width / scale / amountlids - 0.01,
        lidHeight / scale - 0.01,
        currDepth / scale - 0.01,
        0xdddddd
      );
      aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1] = makeCircle(
        scene,
        -width / scale / 2 +
          i * (width / scale / amountlids) +
          width / scale / amountlids / 2,
        currHeight / scale / 2 + lidHeight / scale + 0.001,
        currDepth / scale / 2 - fingerholediff / scale,
        fingerholediameter / scale / 2,
        0x000000
      );
      aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1].rotateX(
        (PI * 270) / 180
      );
      aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2] = makeCircle(
        scene,
        -width / scale / 2 +
          i * (width / scale / amountlids) +
          width / scale / amountlids / 2,
        currHeight / scale / 2 + lidHeight / scale + 0.001,
        0,
        griddiameter / scale / 2,
        0x000000
      );
      aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2].rotateX(
        (PI * 270) / 180
      );
      if (!coverPanelsPresent) {
        //hide the panels if that option isn't crossed on
        scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips]);
        scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1]);
        scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2]);
      }
    }
    stripsVisibleInvisible(scene, width, currDepth, aquariumTypePlanks);
    currWidth = width;
    return;
  } else {
    //if the amount of widthstrips hasn't changed
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks[i + 14].position.x =
        -width / scale / 2 +
        ((i + 1) * (width / scale - 2 * glassWidth)) / (amountWidthStrips + 1);
    }
  }
  amountlids = newAmountlids;
  //remove all lids, because the planks can be in the wrong place is the aquariumtypeplanks array
  //if the amount of widthstrips changes all the aquariumtypeplanks after that are removed as well
  //this is because it began to be complicated if the amount of widthstrips got smaller or bigger and all the lids would be moved
  for (
    let i = aquariumTypePlanks.length - 1;
    i >= 14 + amountWidthStrips;
    i--
  ) {
    scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
  for (let i = 0; i < amountlids; i++) {
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips] = makePlank(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      currHeight / scale / 2 + lidHeight / scale / 2,
      0,
      width / scale / amountlids - 0.01,
      lidHeight / scale - 0.01,
      currDepth / scale - 0.01,
      0xdddddd
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      currHeight / scale / 2 + lidHeight / scale + 0.001,
      currDepth / scale / 2 - fingerholediff / scale,
      fingerholediameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1].rotateX(
      (PI * 270) / 180
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      currHeight / scale / 2 + lidHeight / scale + 0.001,
      0,
      griddiameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2].rotateX(
      (PI * 270) / 180
    );
    if (!coverPanelsPresent) {
      scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips]);
      scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1]);
      scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2]);
    }
  }
  //make the lengthstrips invisible if the width or depth is too small
  stripsVisibleInvisible(scene, width, currDepth, aquariumTypePlanks);
  currWidth = width;
}

export function changeTurtleHeight(
  height: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  for (let i = 0; i < aquariumTypePlanks.length; i++) {
    // change the height of all the planks except the islands
    // we can do this because everything lifts as much as the highest point of the aquarium, if there are planks that don't do this you should define that underneath
    if (i !== 8 && i !== 9 && i !== 10 && i !== 11 && i !== 12 && i !== 13)
      aquariumTypePlanks[i].position.y += (height - currHeight) / scale / 2;
  }
  currHeight = height;
}

export function changeTurtleGlassWidth(
  newGlassWidth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //lengthstrips
  aquariumTypePlanks[4].geometry.scale(
    (currWidth / scale - 2 * newGlassWidth) /
      (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[5].geometry.scale(
    (currWidth / scale - 2 * newGlassWidth) /
      (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[4].position.z += newGlassWidth - glassWidth;
  aquariumTypePlanks[5].position.z += glassWidth - newGlassWidth;

  //width strips
  aquariumTypePlanks[6].geometry.scale(
    1,
    1,
    (currDepth / scale - 2 * newGlassWidth) /
      (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[7].geometry.scale(
    1,
    1,
    (currDepth / scale - 2 * newGlassWidth) /
      (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[6].position.x += newGlassWidth - glassWidth;
  aquariumTypePlanks[7].position.x += glassWidth - newGlassWidth;

  //left island
  aquariumTypePlanks[8].geometry.scale(
    1,
    1,
    (currDepth / scale - newGlassWidth * 2) /
      (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[9].geometry.scale(
    1,
    1,
    (currDepth / scale - newGlassWidth * 2) /
      (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[10].position.z =
    -currDepth / scale / 2 + bridgeWidthDepth / 2 + newGlassWidth;
  aquariumTypePlanks[8].position.x =
    -currWidth / scale / 2 + platformWidth / 2 + newGlassWidth;
  aquariumTypePlanks[9].position.x =
    -currWidth / scale / 2 +
    platformWidth +
    newGlassWidth -
    turtleGlassWidth / 2;
  aquariumTypePlanks[10].position.x =
    -currWidth / scale / 2 + platformWidth + newGlassWidth / 2 + 7.07 / scale;

  //rigth island
  aquariumTypePlanks[11].geometry.scale(
    1,
    1,
    (currDepth / scale - newGlassWidth * 2) /
      (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[12].geometry.scale(
    1,
    1,
    (currDepth / scale - newGlassWidth * 2) /
      (currDepth / scale - glassWidth * 2)
  );
  aquariumTypePlanks[13].position.z = -(
    -currDepth / scale / 2 +
    bridgeWidthDepth / 2 +
    newGlassWidth
  );
  aquariumTypePlanks[11].position.x = -(
    -currWidth / scale / 2 +
    platformWidth / 2 +
    newGlassWidth
  );
  aquariumTypePlanks[12].position.x = -(
    -currWidth / scale / 2 +
    platformWidth +
    newGlassWidth -
    turtleGlassWidth / 2
  );
  aquariumTypePlanks[13].position.x = -(
    -currWidth / scale / 2 +
    platformWidth +
    newGlassWidth / 2 +
    7.07 / scale
  );

  //widthstrips
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 14].geometry.scale(
      (currDepth / scale - 2 * newGlassWidth) /
        (currDepth / scale - 2 * glassWidth),
      1,
      1
    );
  }
  glassWidth = newGlassWidth;
}

//check in the jsonfile how many widthstrips are needed with a certain width and depth
function getAmountWidthStrips(width: number, depth: number) {
  //check where depth is at (between which values is depth)
  let horizontalIndex = getWidthStripValues()["horizontalValues"].length-1;;
  for (let i = 0; i < getWidthStripValues()["horizontalValues"].length; i++) {
    if (depth < Number(getWidthStripValues()["horizontalValues"][i])) {
      horizontalIndex = i + 1;
      break;
    }
  }

  //check where width is at (between which values is width)
  let verticalIndex = getWidthStripValues()["verticalValues"].length;;
  for (let i = 0; i < getWidthStripValues()["verticalValues"].length; i++) {
    if (width < Number(getWidthStripValues()["verticalValues"][i])) {
      verticalIndex = i + 1;
      break;
    }
  }

  //check how many widthstrips there should be
  for (let i = 0; i < getWidthStripValues()["amount_strips"].length; i++) {
    if (
      verticalIndex === Number(getWidthStripValues()["amount_strips"][i][0]) &&
      horizontalIndex === Number(getWidthStripValues()["amount_strips"][i][1])
    ) {
      return Number(getWidthStripValues()["amount_strips"][i][2]);
    }
  }
  return 0;
}

// this shows and hides the lightcover, every time this is called it is switched
export function changeTurtleLightCover(
  scene: THREE.Scene,
  aquariumTypePlanks: THREE.Mesh[],
  drawLightCover: boolean
) {
  lightCoverPresent = drawLightCover;
  if (!lightCoverPresent) {
    //if the lightcover was on (lightcoverPresent was true), hide it
    scene.remove(aquariumTypePlanks[0]);
    scene.remove(aquariumTypePlanks[1]);
    scene.remove(aquariumTypePlanks[2]);
    scene.remove(aquariumTypePlanks[3]);
    lightCoverPresent = false;
    if (coverPanelsPresent) {
      //if the coverpanels are showing, hide them as well
      changeTurtleCoverpanels(scene, aquariumTypePlanks, coverPanelsPresent);
    }
  } else {
    //show the lightcover
    scene.add(aquariumTypePlanks[0]);
    scene.add(aquariumTypePlanks[1]);
    scene.add(aquariumTypePlanks[2]);
    scene.add(aquariumTypePlanks[3]);
    lightCoverPresent = true;
  }
}

// this shows and hides the coverpanels, every time this is called, it is switched
export function changeTurtleCoverpanels(
  scene: THREE.Scene,
  aquariumTypePlanks: THREE.Mesh[],
  drawPanels: boolean
) {
  coverPanelsPresent = drawPanels;
  if (!coverPanelsPresent) {
    // if the coverpanels are shown, hide them
    coverPanelsPresent = false;
    for (let i = 0; i < amountlids; i++) {
      scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips]);
      scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1]);
      scene.remove(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2]);
    }
  } else {
    //show the coverpanels
    coverPanelsPresent = true;
    for (let i = 0; i < amountlids; i++) {
      scene.add(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips]);
      scene.add(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 1]);
      scene.add(aquariumTypePlanks[i * 3 + 14 + amountWidthStrips + 2]);
    }
  }
}

//this is for the island option, where the island should be, left, rigth, on both sides or no island at all
export function changeTurtleIsland(
  scene: THREE.Scene,
  turtleIsland: TurtleIsland,
  aquariumTypePlanks: THREE.Mesh[]
) {
  // console.log("kom hier langs")
  for (let i = 8; i <= 13; i++) {
    scene.remove(aquariumTypePlanks[i]);
  }
  switch (turtleIsland) {
    case TurtleIsland.left: {
      for (let i = 8; i <= 10; i++) {
        scene.add(aquariumTypePlanks[i]);
      }
      break;
    }
    case TurtleIsland.right: {
      for (let i = 11; i <= 13; i++) {
        scene.add(aquariumTypePlanks[i]);
      }
      break;
    }
    case TurtleIsland.twosided: {
      for (let i = 8; i <= 13; i++) {
        scene.add(aquariumTypePlanks[i]);
      }
      break;
    }
  }
}

export function changeTurtleTexture(
  texture: String,
  closetPlanks: THREE.Mesh[][]
) {}

export const amountStripsTurtle = () => {
  return amountWidthStrips;
};
