import React from "react";
import {
  Aquarium,
  AquariumType,
  WaterType,
  SidesInfo,
  WindowPaintOption,
  SiliconColor,
  FilterOptions,
  PvcPipeworkPackage,
  FurnitureType,
  CoverPanelsColor,
} from "../../@types/aquarium";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Check, X } from "@phosphor-icons/react";
import { aquariumTypeOptions } from "../../data/aquariumTypes";
import { ImageOption } from "../../@types/configurator";
import { decorTextures } from "../../data/textures";
import {
  doorTypeOptions,
  electraRuimteOptions,
  extraPvcPipeworkPackageOptions,
  filterLocationOptions,
  filterOptions,
  fullGlassCoverOptions,
  furnitureTypeOptions,
  turtleIslandOptions,
} from "../../data/aquarium";
import { info } from "console";
import { ProductData } from "../../@types/product";
import { getGlassWidth, scale } from "../aquariumComponents/tank";
import { getAmountWidthStripsAquarium } from "../../utils/aquarium";
import { totalMarineDimensions } from "../aquariumComponents/marineConform";
import { totalAluminiumDimensions } from "../aquariumComponents/aluminiumFinish";
import { totalDecorDimensions } from "../aquariumComponents/decorFinish";
import { isInStringEnum } from "../../utils/configurator";
import {
  getAquariumFilterPrice,
  getAquariumFurniturePrice,
  getAquariumGlassPrice,
  getAquariumTypePrice,
  getPrice,
} from "../aquariumComponents/priceCalculator";
import { AllTextObject } from "../../@types/language";

type Props = { allText: AllTextObject };

function OverviewTable({ allText }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const dispatch = useDispatch();

  function getImageOptionTitle(value: string, options: any) {
    const item = options.find((item: ImageOption) =>
      item.id === value ? item.title : null
    );
    return item ? item.title : null;
  }
  const getSidesPaintedFormat = (title: string, sidesInfo: SidesInfo) => {
    let text = "";
    if (sidesInfo.left.colorPainted) {
      text += `${allText.tid_gen_17}, `;
    }
    if (sidesInfo.right.colorPainted) {
      text += `${allText.tid_gen_18}, `;
    }
    if (sidesInfo.back.colorPainted) {
      text += `${allText.tid_gen_24}, `;
    }
    return getTableFormat(title, text || false);
  };
  const getSidesOptiwhiteFormat = (title: string, sidesInfo: SidesInfo) => {
    let text = "";
    if (sidesInfo.left.optiWhite) {
      text += `${allText.tid_gen_17}, `;
    }
    if (sidesInfo.right.optiWhite) {
      text += `${allText.tid_gen_18}, `;
    }
    if (sidesInfo.front.optiWhite) {
      text += `${allText.tid_gen_25}, `;
    }
    if (sidesInfo.back.optiWhite) {
      text += `${allText.tid_gen_24}, `;
    }
    return getTableFormat(title, text || false);
  };

  const hasPaintedWindows = (sidesInfo: SidesInfo) => {
    return sidesInfo.left.colorPainted ||
      sidesInfo.right.colorPainted ||
      sidesInfo.back.colorPainted
      ? true
      : false;
  };
  const hasOptiwhiteWindows = (sidesInfo: SidesInfo) => {
    return sidesInfo.left.optiWhite ||
      sidesInfo.right.optiWhite ||
      sidesInfo.front.optiWhite ||
      sidesInfo.back.optiWhite
      ? true
      : false;
  };

  const getClosetDimensions = (type: FurnitureType) => {
    switch (type) {
      case FurnitureType.marine:
        return totalMarineDimensions();
      case FurnitureType.aluminium:
        return totalAluminiumDimensions();
      case FurnitureType.decor:
        return totalDecorDimensions();
    }
  };

  const getTechnicsProduct = (title: string, items: ProductData[]) => {
    const countDuplicates = (items: ProductData[]) => {
      const counts: any = {};
      items.forEach((value: ProductData) => {
        if (!counts[value.name]) {
          counts[value.name] = 1;
        } else {
          counts[value.name]++;
        }
      });
      return counts;
    };
    const result = countDuplicates(items);
    let showedTitle = 0;

    const tableData = Object.entries(result).map((value) => {
      showedTitle += 1;
      return getTableFormat(
        showedTitle === 1 ? title : "",
        `${value[1]}x ${value[0]}`
      );
    });
    return tableData;
  };

  const checkSpecialValue = (value: any) => {
    if (typeof value === "boolean") {
      return value === false ? <X /> : value === true ? <Check /> : null;
    } else if (isInStringEnum(value, WaterType)) {
      return value === WaterType.fresh
        ? allText.tid_gen_12
        : allText.tid_gen_13;
    } else if (isInStringEnum(value, WindowPaintOption)) {
      return value === WindowPaintOption.black
        ? allText.tid_gen_14
        : allText.tid_gen_23;
    } else if (isInStringEnum(value, SiliconColor)) {
      return value === SiliconColor.black
        ? allText.tid_gen_14
        : allText.tid_gen_15;
    } else if (isInStringEnum(value, CoverPanelsColor)) {
      return value === CoverPanelsColor.black
        ? allText.tid_gen_14
        : value === CoverPanelsColor.white
        ? allText.tid_gen_20
        : CoverPanelsColor.gray
        ? allText.tid_gen_21
        : allText.tid_gen_22;
    } else {
      return null;
    }
  };

  const getTableFormat = (title: string, value: any) => {
    const specialValue = checkSpecialValue(value);

    return (
      <tr
        className={title ? "" : "overview-table-td-no-border"}
        key={title + "-overview-title"}
      >
        <td>{title}</td>
        <td>{specialValue || value}</td>
      </tr>
    );
  };
  const getExtraTableFormat = (title: string) => {
    return (
      <tr
        className="overview-table-extra-info"
        key={title + "overview-extra-title"}
      >
        <td>{title}</td>
        <td></td>
      </tr>
    );
  };
  const getTableSubtitle = (title: string, price: number) => {
    return (
      <tr className="overview-table-subtitle" key={title + "overview-subtitle"}>
        <th>{title}</th>
        <th>€ {price.toFixed(2).toString().replace(".", ",")}</th>
      </tr>
    );
  };

  return (
    <table className="overview-table" cellSpacing={0}>
      <thead>
        <tr className="overview-table-title">
          <th>{allText.tid_ovw_01}</th>
          <th></th>
        </tr>
      </thead>
      <tbody className="overview-table-tbody">
        {getTableSubtitle(allText.tid_gen_03, getAquariumGlassPrice(aquarium))}
        {getTableFormat(
          allText.tid_gen_09,
          `${aquarium.aquariumDimensions.width} cm`
        )}
        {getTableFormat(
          allText.tid_gen_10,
          `${aquarium.aquariumDimensions.depth} cm`
        )}
        {getTableFormat(
          allText.tid_gen_11,
          `${aquarium.aquariumDimensions.height} cm`
        )}
        {aquarium.aquariumType &&
          getAmountWidthStripsAquarium(aquarium.aquariumType) > 0 &&
          getTableFormat(
            allText.tid_ovw_04,
            `${getAmountWidthStripsAquarium(aquarium.aquariumType)}`
          )}
        {getTableFormat(
          allText.tid_ovw_05,
          `${
            getGlassWidth(
              aquarium.aquariumDimensions.width,
              aquarium.aquariumDimensions.depth,
              aquarium.aquariumDimensions.height
            ) *
            scale *
            10
          } mm`
        )}

        {getTableSubtitle(allText.tid_ovw_02, getAquariumTypePrice(aquarium))}
        {getTableFormat(allText.tid_ovw_06, aquarium.waterType)}
        {aquarium.aquariumType &&
          getTableFormat(
            allText.tid_ovw_07,
            getImageOptionTitle(
              aquarium.aquariumType,
              aquariumTypeOptions(allText)
            )
          )}
        {aquarium.turtleIsland &&
          getTableFormat(
            allText.tid_ovw_08,
            getImageOptionTitle(
              aquarium.turtleIsland,
              turtleIslandOptions(allText)
            )
          )}
        {aquarium.fullGlassCoverPanels &&
          getTableFormat(
            allText.tid_ovw_09,
            getImageOptionTitle(
              aquarium.fullGlassCoverPanels,
              fullGlassCoverOptions(allText)
            )
          )}
        {aquarium.aquariumTexture &&
          getTableFormat(
            allText.tid_ovw_10,
            getImageOptionTitle(
              aquarium.aquariumTexture,
              decorTextures(allText)
            )
          )}
        {aquarium.extra.siliconeColor &&
          getTableFormat(allText.tid_ovw_11, aquarium.extra.siliconeColor)}
        {aquarium.sharpenGlass &&
          getTableFormat(allText.tid_ovw_12, aquarium.sharpenGlass)}
        {aquarium.lightCover &&
          getTableFormat(allText.tid_ovw_13, aquarium.lightCover)}
        {aquarium.grindlist &&
          getTableFormat(allText.tid_st2_24, aquarium.grindlist)}
        {aquarium.coverPanels &&
          getTableFormat(allText.tid_ovw_14, aquarium.coverPanels)}
        {aquarium.coverPanelsColor &&
          aquarium.coverPanels &&
          getTableFormat(allText.tid_ovw_15, aquarium.coverPanelsColor)}
        {aquarium.extra.waterproofPlate &&
          getTableFormat(allText.tid_ovw_16, aquarium.extra.waterproofPlate)}

        {(hasPaintedWindows(aquarium.aquariumSides) ||
          hasOptiwhiteWindows(aquarium.aquariumSides)) &&
          getTableSubtitle(allText.tid_ovw_03, 0)}
        {aquarium.aquariumSides &&
          hasPaintedWindows(aquarium.aquariumSides) &&
          getSidesPaintedFormat(allText.tid_ovw_17, aquarium.aquariumSides)}
        {hasPaintedWindows(aquarium.aquariumSides) &&
          getTableFormat(allText.tid_ovw_18, aquarium.windowPaintColor)}
        {aquarium.aquariumSides &&
          hasOptiwhiteWindows(aquarium.aquariumSides) &&
          getSidesOptiwhiteFormat(allText.tid_ovw_19, aquarium.aquariumSides)}

        {getTableSubtitle(allText.tid_gen_06, getAquariumFilterPrice(aquarium))}
        {aquarium.filter.filterType &&
          getTableFormat(
            allText.tid_ovw_20,
            getImageOptionTitle(
              aquarium.filter.filterType,
              filterOptions(allText)
            )
          )}
        {aquarium.filter.filterType === FilterOptions.bio &&
          getExtraTableFormat(allText.tid_ovw_21)}
        {aquarium.filter.externalDryroom &&
          getTableFormat(allText.tid_ovw_22, aquarium.filter.externalDryroom)}
        {aquarium.filter.filterLocation &&
          getTableFormat(
            allText.tid_ovw_23,
            getImageOptionTitle(
              aquarium.filter.filterLocation,
              filterLocationOptions(allText)
            )
          )}
        {aquarium.extra.pvcPipeworkPackage &&
          getTableFormat(
            allText.tid_ovw_24,
            getImageOptionTitle(
              aquarium.extra.pvcPipeworkPackage,
              extraPvcPipeworkPackageOptions(allText)
            )
          )}
        {aquarium.extra.pvcPipeworkPackage === PvcPipeworkPackage.byAqualife &&
          getExtraTableFormat(allText.tid_ovw_25)}
        {aquarium.filter.bioCoverPanels &&
          getTableFormat(allText.tid_ovw_26, aquarium.filter.bioCoverPanels)}
        {aquarium.filter.osmoseCompartiment &&
          getTableFormat(
            allText.tid_ovw_27,
            aquarium.filter.osmoseCompartiment
          )}
        {aquarium.filter.boreholeLeft &&
          getTableFormat(allText.tid_ovw_28, aquarium.filter.boreholeLeft)}
        {aquarium.filter.boreholeRight &&
          getTableFormat(allText.tid_ovw_29, aquarium.filter.boreholeRight)}

        {getTableSubtitle(
          allText.tid_gen_07,
          getAquariumFurniturePrice(aquarium)
        )}
        {aquarium.furniture.furnitureType &&
          getTableFormat(
            allText.tid_ovw_30,
            getImageOptionTitle(
              aquarium.furniture.furnitureType,
              furnitureTypeOptions(allText)
            )
          )}
        {aquarium.furniture.furnitureType === FurnitureType.marine &&
          getExtraTableFormat(allText.tid_ovw_31)}
        {aquarium.furniture.furnitureType !== FurnitureType.none &&
          getTableFormat(
            allText.tid_ovw_32,
            `${aquarium.furniture.dimensions.baseHeight} cm`
          )}
        {(aquarium.furniture.furnitureType === FurnitureType.marine ||
          aquarium.furniture.furnitureType === FurnitureType.aluminium ||
          aquarium.furniture.furnitureType === FurnitureType.decor) &&
          getTableFormat(
            allText.tid_ovw_34,
            `${getClosetDimensions(aquarium.furniture.furnitureType)?.width} cm`
          )}
        {(aquarium.furniture.furnitureType === FurnitureType.marine ||
          aquarium.furniture.furnitureType === FurnitureType.aluminium ||
          aquarium.furniture.furnitureType === FurnitureType.decor) &&
          getTableFormat(
            allText.tid_ovw_35,
            `${getClosetDimensions(aquarium.furniture.furnitureType)?.depth} cm`
          )}
        {aquarium.furniture.dimensions.topHeight > 0 &&
          getTableFormat(
            allText.tid_ovw_33,
            `${aquarium.furniture.dimensions.topHeight} cm`
          )}
        {aquarium.furniture.doorType &&
          aquarium.furniture.furnitureType === FurnitureType.aluminium &&
          getTableFormat(
            allText.tid_ovw_36,
            getImageOptionTitle(
              aquarium.furniture.doorType,
              doorTypeOptions(allText)
            )
          )}
        {(aquarium.furniture.furnitureType === FurnitureType.decor ||
          aquarium.furniture.furnitureType === FurnitureType.marine) &&
          getTableFormat(allText.tid_ovw_36, allText.tid_ovw_37)}
        {aquarium.furniture.electraRuimte &&
          getTableFormat(
            allText.tid_ovw_38,
            getImageOptionTitle(
              aquarium.furniture.electraRuimte,
              electraRuimteOptions(allText)
            )
          )}
        {aquarium.furniture.betonPlex &&
          getTableFormat(allText.tid_ovw_39, aquarium.furniture.betonPlex)}
        {aquarium.furniture.seeThrough &&
          getTableFormat(allText.tid_ovw_40, aquarium.furniture.seeThrough)}
        {aquarium.furniture.backWall34 &&
          getTableFormat(allText.tid_ovw_41, aquarium.furniture.backWall34)}
        {aquarium.furniture.removableSideLeft &&
          getTableFormat(
            allText.tid_ovw_42,
            aquarium.furniture.removableSideLeft
          )}
        {aquarium.furniture.removableSideRight &&
          getTableFormat(
            allText.tid_ovw_43,
            aquarium.furniture.removableSideRight
          )}
        {aquarium.furniture.aquaswitch &&
          getTableFormat(allText.tid_ovw_44, aquarium.furniture.aquaswitch)}

        {getTableSubtitle(allText.tid_gen_08, 0)}
        {aquarium.technics.boosterPump.length > 0 &&
          getTechnicsProduct(allText.tid_st6_01, aquarium.technics.boosterPump)}
        {aquarium.technics.led.length > 0 &&
          getTechnicsProduct(allText.tid_st6_08, aquarium.technics.led)}
        {aquarium.technics.heating.length > 0 &&
          getTechnicsProduct(allText.tid_st6_12, aquarium.technics.heating)}
        {aquarium.technics.filter.length > 0 &&
          getTechnicsProduct(allText.tid_st6_16, aquarium.technics.filter)}
        {aquarium.technics.circulationPump.length > 0 &&
          getTechnicsProduct(
            allText.tid_st6_20,
            aquarium.technics.circulationPump
          )}
        {aquarium.technics.proteinSkimmer.length > 0 &&
          getTechnicsProduct(
            allText.tid_st6_24,
            aquarium.technics.proteinSkimmer
          )}
        {aquarium.technics.automaticRefillSystem.length > 0 &&
          getTechnicsProduct(
            allText.tid_st6_28,
            aquarium.technics.automaticRefillSystem
          )}
        {aquarium.technics.co2System.length > 0 &&
          getTechnicsProduct(allText.tid_st6_36, aquarium.technics.co2System)}
        {aquarium.technics.interior.length > 0 &&
          getTechnicsProduct(allText.tid_st6_40, aquarium.technics.interior)}
      </tbody>
      <tfoot>
        <tr className="overview-table-footer-title">
          <td>{allText.tid_ovw_45}</td>
          <td>
            €{" "}
            {(Math.round(getPrice(aquarium) * 100) / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")}
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default OverviewTable;
